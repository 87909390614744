import React from 'react';
import { Header, List } from 'semantic-ui-react';
import Layout from '../components/layout';
import Helmet from '../components/Helmet';
import * as DefaultTemplate from '../components/ContentPages/DefaultTemplate';


const list = [
    {
        href: '/14-invaluable-habits/',
        title: '14 Invaluable Habits: What to Do With Your Money',
    },
    {
        href: '/medical-bills/',
        title: '18 Simple Ways to Minimize (or Erase) Scary Medical Bills',
    },
    {
        href: '/4-keys-out-of-debt/',
        title: '4 Keys to Getting Out of Debt',
    },
    {
        href: '/student-loans/',
        title: '9 Effective Ways to Manage Your Student Loan Debt',
    },
    {
        href: '/debt-settlement/accredited-debt-relief-review/',
        title: 'Accredited Debt Relief Review',
    },
    {
        href: '/debt-throughout-history/',
        title: 'An Exploration of Debt Throughout History',
    },
    {
        href: '/debt-repayment-plan/',
        title: 'Creating a Debt Repayment Plan',
    },
    {
        href: '/credit-repair/',
        title: 'Credit Repair: Everything You Need to Know',
    },
    {
        href: '/debt-collection/',
        title: 'Debt Collectors Calling? Know Your Rights and How to Deal With Them',
    },
    {
        href: '/demystified/',
        title: 'Debt Consolidation Demystified: 5 Things Every Consumer Needs to Know',
    },
    {
        href: '/debt-settlement/debt-management-plan/',
        title: 'Debt Management Plans',
    },
    {
        href: '/debt-relief/',
        title: 'Debt Relief: What It Means',
    },
    {
        href: '/debt-settlement/debtmd/',
        title: 'DebtMD Review',
    },
    {
        href: '/loans/do-consolidation-loans-hurt-credit-score/',
        title: 'Do Consolidation Loans Hurt Your Credit Score? Get the Facts',
    },
    {
        href: '/debt-settlement/guide/',
        title: 'Everything You Need to Know About Debt Settlement',
    },
    {
        href: '/credit-counseling/family-budget-services-review/',
        title: 'Family Budget Services Review',
    },
    {
        href: '/debt-settlement/freedom-debt-relief/',
        title: 'Freedom Debt Relief Review',
    },
    {
        href: '/us-currency-design/',
        title: 'History of the US Dollar Design',
    },
    {
        href: '/loans/debt-consolidation-loan-bad-credit/',
        title: 'How Can I Get a Debt Consolidation Loan With Bad Credit?',
    },
    {
        href: '/credit-counseling/how-it-works/',
        title: 'How Credit Counseling Works, and How It Can Help',
    },
    {
        href: '/jim-davies-debt/',
        title: 'How Jim Davies Paid off $400,000 in Debt in 21 Months',
    },
    {
        href: '/stop-debt-collector-calls/',
        title: 'How To Permanently Stop Debt Collectors From Calling You',
    },
    {
        href: '/tyler-perry/',
        title: 'How Tyler Perry Went From Homeless To A Net Worth of $400 Million',
    },
    {
        href: '/national-debt-effects/',
        title: 'How the $20 Trillion National Debt Will Affect the Average American',
    },
    {
        href: '/credit-card-debt/',
        title: 'How to Deal With Credit Card Debt',
    },
    {
        href: '/how-to-get-out-of-debt/',
        title: 'How to Get Out of Debt',
    },
    {
        href: '/loans/is-it-bad-consolidate-debt/',
        title: 'Is it Bad to Consolidate Debt',
    },
    {
        href: '/loans/lending-point-review/',
        title: 'Lending Point Review',
    },
    {
        href: '/debt-settlement/national-debt-relief/',
        title: 'National Debt Relief Review',
    },
    {
        href: '/us-debt-presidents/',
        title: 'The $28 Trillion U.S. Debt: Which President Contributed the Most',
    },
    {
        href: '/loans/debt-consolidation-options/',
        title: 'The Best Debt Consolidation Options for 2019',
    },
    {
        href: '/debt-management/',
        title: 'The Most Effective Ways to Manage Your Debt',
    },
    {
        href: '/6-steps-out-of-debt/',
        title: 'The Simple 6-Step Process To Getting Out of Debt',
    },
    {
        href: '/credit-counseling/consumer-credit-counseling/',
        title: 'The Ultimate Consumer Credit Counseling Guide',
    },
    {
        href: '/budgeting/',
        title: 'The Ultimate Guide to Budgeting',
    },
    {
        href: '/stick-to-your-budget/',
        title: 'Tips to Help You Stick to Your Budget',
    },
    {
        href: '/bankruptcy/',
        title: 'Understanding Bankruptcy, When It Makes Sense and How to Avoid It',
    },
    {
        href: '/understanding-debt-consolidation/',
        title: 'Understanding Debt Consolidation: What It Does and How It Can Help',
    },
    {
        href: '/repair-your-credit/',
        title: 'Ways to Repair Your Credit',
    },
    {
        href: '/analyzing-debt-consolidation/',
        title: 'We Analyzed 1 Million Debt Consolidation Articles (And This is What We Learned)',
    },
    {
        href: '/debt-management-program/',
        title: 'What Is a Debt Management Program?',
    },
    {
        href: '/credit-counseling/ecredit-advisor-review/',
        title: 'eCredit Advisor Review',
    },
];


const SitemapPage = () => (
    <Layout
        showDisclosures={false}
    >
        <Helmet
            title="Sitemap"
            description="The sitemap for DebtConsolidation.com."
            robots="noindex, follow"
        />

        <DefaultTemplate.Wrapper>
            <DefaultTemplate.Body>
                <Header as="h1" size="large">
                    Sitemap
                </Header>
                <List
                    bulleted
                    relaxed
                >
                    {list.map((item, idx) => (
                        <List.Item
                            key={item.href + idx.toString()}
                            as="a"
                        >
                            <List.Content>
                                <a data-sitemap-url href={item.href} title={item.title}>{item.title}</a>
                            </List.Content>
                        </List.Item>
                    ))}
                </List>
            </DefaultTemplate.Body>
        </DefaultTemplate.Wrapper>
    </Layout>
);


export default SitemapPage;
